/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import {arrayMove, SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Form from "react-bootstrap/Form";
import ReactMapGL, {Marker} from "react-map-gl";
import NPIf from "np-if";
import {money} from "../../lib/money/money";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "react-bootstrap/Badge"
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import CardBody from "react-bootstrap/CardBody";
import Spinner from "react-bootstrap/Spinner";
import { Toaster, toast } from 'sonner';
import ListGroupItem from "react-bootstrap/ListGroupItem";

//style
import "../../scss/components/route.scss";

//components
import ViewEvidencesComponent from "./ViewEvidencesComponent";

// geo
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import {getDistance} from 'geolib';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

//token TODO: replace to nbio token
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';
const _            = require('underscore');

const ROUTE_ACTIONS = [
        {action:'PICK_UP', name:'Recoger',icon:'box',variant:'success'},
        {action:'DELIVER', name:'Entregar',icon:'home',variant:'primary'},
        {action:'REPAIR', name:'Reparar',icon:'tools',variant: 'danger'},
        {action:'GAS', name:'Gas',icon:'gas-pump',variant:'warning'}
];
const addressStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "México",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
];
const HOURS = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
const MINUTES = [
    "00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24",
    "25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47",
    "48","49","50","51","52","53","54","55","56","57","58","59"]
//---------functions----------------------------------------------------------------------------------------------------

const DragHandle = SortableHandle(() =>
    <Button size="sm" variant="medium" style={{cursor: 'move'}}>
        <FontAwesomeIcon icon={"grip-vertical"}/>
    </Button>
);

const SortableItem = SortableElement(({value, onClickMove, i, length, onDelete,onHover,onViewEvidences,onEdit}) => {
    const region = value?.order?.region || {};
    const order = value.order;
    const address = order ? order.shipping.address : value.address;
    const borough = order ? order.shipping.borough : value.borough;
    const state = order ? order.shipping.state : value.state;
    const zipCode = order ? order.shipping.zipCode : value.zipCode;
    const reference = order ? order.shipping.reference : value.reference;

    const styleBadge = {backgroundColor: region ? region.color : 'transparent', color: 'white'};

    const status = (value) => {
        let variant = '';
        let txt = '';

        switch (value) {
            case 'processing':
                variant = 'warning';
                txt = 'Procesando';
                break;
            case 'received':
                variant = 'warning';
                txt = 'Recibida';
                break;
            case 'amount_validated':
                variant = 'warning';
                txt = 'Monto validado';
                break;
            case 'on_route':
                variant = 'warning';
                txt = 'En camino';
                break;
            case 'delivering':
                variant = 'warning';
                txt = 'Entregando';
                break;
            case 'delivered':
                variant = 'success';
                txt = 'Entregado';
                break;
            case 'finished':
                variant = 'success';
                txt = 'Terminado';
                break;
            case 'cancelled':
                variant = 'danger';
                txt = 'Cancelada';
                break;
            default:
                variant = 'info';
                txt = '---';
                break;
        }
        return (
            <span className={`text-${variant}`}>{txt}</span>
        )
    }

    const renderActionBtn = (action) => {
        const foundAction = ROUTE_ACTIONS.find((a) => a.action === action);
        return (
            <Badge bg={foundAction?.variant}>
                {foundAction?.name}&nbsp;
                <FontAwesomeIcon icon={foundAction?.icon}/>
            </Badge>
        )
    }
    const formatTime = (hour,minute) =>{
        const h = hour || '';
        const m = minute || '';
        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
    }
    return (
        <ListGroup.Item as={'li'} className={'d-flex align-items-start border-0 ps-0 pe-0'}>
            {/*Item Status & Time*/}
            <div className={'py-3'} style={{width: '100px'}}>
                <h3>
                    {
                        value.status === "CLOSED" ?
                            <FontAwesomeIcon icon={'check-circle'} color={"#00b2e3"}/> :
                            <FontAwesomeIcon icon={'dot-circle'} color={"gray"}/>
                    }
                    &nbsp;
                    {i+1}
                </h3>
                <p className={'text-center'}>{formatTime(value?.hour, value?.minute)}</p>
            </div>
            {/*Item Card*/}
            <Card className={'w-100'}>
                <CardBody>
                    <div className={'mb-2'}>
                        {renderActionBtn(value.action)}
                        <small className={'text-muted'}>{value.name ? ` / ${value.name}` : ''}</small>
                    </div>
                    {/*Render instructions or order main info*/}
                    {
                        order ?
                        <p>
                            <Link to={`/orders/${order._id}`}>{order.orderId}</Link><br/>
                            <small> Estado de la orden: {status(order.status)}</small><br/>
                            <small> Monto total de la orden: {money(order.total)}</small>
                        </p>
                            :
                        <p>{value.instructions}</p>
                    }
                    <p className={'text-muted'}>
                        <small>
                            { region ?
                                <span className={'badge'} style={styleBadge}>{region.name}</span> :
                                <span></span>
                            }
                            <br/>
                            {address}, {borough}<br/>
                            {value.locality} {state}<br/>
                            {zipCode}<br/>
                            {reference}
                        </small>
                    </p>
                    {/*Btns wrapper*/}
                    <div className={"d-flex mt-5"}>
                        {
                            value.evidences.length > 0 ?
                                <Button variant={'link'}
                                        className={'p-0'}
                                        onClick={() => onViewEvidences(value)}>
                                    Ver evidencias ({value.evidences.length})
                                </Button>
                                : null
                        }
                        <ButtonGroup className={'ml-auto mr-2'}>
                            <Button size={'sm'}
                                    variant={'outline-primary'}
                                     onClick={() => onEdit(value)}>
                                <FontAwesomeIcon icon={'pen'}/>
                            </Button>
                            <Button size={'sm'}
                                    variant={'outline-primary'}
                                     onClick={() => onHover(value)}>
                                <FontAwesomeIcon icon={'eye'}/>
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup>
                            <DropdownButton variant={'medium'} title={'Mover'} as={ButtonGroup} size={'sm'}>
                                <Dropdown.Item onClick={() => onClickMove(value, i, i - 1)}>Arriba</Dropdown.Item>
                                <Dropdown.Item onClick={() => onClickMove(value, i, i + 1)}>Abajo</Dropdown.Item>
                                <Dropdown.Item onClick={() => onClickMove(value, i, 0)}>Mover al inicio</Dropdown.Item>
                                <Dropdown.Item onClick={() => onClickMove(value, i, length)}>Mover al
                                    final</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={() => onDelete(value, i)}>Eliminar</Dropdown.Item>
                            </DropdownButton>
                            <DragHandle></DragHandle>
                        </ButtonGroup>
                    </div>
                </CardBody>
            </Card>
        </ListGroup.Item>
    )
});


const SortableList = SortableContainer(({items, onClickMove, onDelete,onHover,onViewEvidences,onEdit}) => {
    return (
        <ListGroup as={'ol'} numbered={false} variant={"flush"}>
            {items.map((value, index) => (
                <SortableItem key={`item-${value?._id}`} index={index} value={value} i={index}
                              onClickMove={onClickMove} length={items.length} onDelete={onDelete} onHover={onHover}
                              onViewEvidences={onViewEvidences} onEdit={onEdit}

                />
            ))}
        </ListGroup>
    );
});

//---------classes------------------------------------------------------------------------------------------------------

class SortableComponent extends React.Component {
    state = {
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
    };

    render() {
        return <SortableList useDragHandle items={this.props.items}
                             onSortEnd={this.props.onSortEnd} onClickMove={this.props.onClickMove}
                             onDelete={this.props.onDelete} onHover={this.props.onHover}
                             onViewEvidences={this.props.onViewEvidences} onEdit={this.props.onEdit}
        />;
    }
}

class Route extends React.Component {
    constructor(p) {
        super(p);
        this.state         = {
            isCreating:true,
            route: {
                name: '',
                orders: [],
                pois:[]
            },
            distances: [],
            isLoading: true,
            viewport: {
                width: '100%',
                height: '600px',
                latitude: 19.432608,
                longitude: -99.133209,
                zoom: 13
            },
            operators: [],
            isNewPointVisible:false,
            pointName:'',
            pointInstructions:'',
            pointAddress:'',
            pointAddress2:'',
            pointZipCode:'',
            pointReference:'',
            pointBorough:'',
            pointLocality:'',
            pointIsEvidenceRequired:false,
            showEvidences:false,
            showNewPointButton:false,
            pointMenuAddress:'',
            newPointUILeft:0,
            newPointLat:0,
            newPointLon:0,
            pointHour:"",
            pointMinute:"",
            pointIsFavorite:false,
            isEditingPoint:false,
            editingPOI:{},
            isSaving:false,
            favoritePOIS:[],
            isFavoritesModalOpen:false
        }
        this.getRoute      = this.getRoute.bind(this);
        this.onClickBtn    = this.onClickBtn.bind(this);
        this.validateRoute = this.validateRoute.bind(this);
        this.create        = this.create.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.onSortEnd     = this.onSortEnd.bind(this);
        this.onClickMove   = this.onClickMove.bind(this);
        this.onDelete      = this.onDelete.bind(this);
        this.cleanAddPoiForm = this.cleanAddPoiForm.bind(this);
        this.goToMarker    = this.goToMarker.bind(this);
        this.onMapClick    = this.onMapClick.bind(this);
        this.startEditing  = this.startEditing.bind(this);
        this.renderFavoritesModal = this.renderFavoritesModal.bind(this);
        this.loadFavorites = this.loadFavorites.bind(this);
        this.addToRoute = this.addToRoute.bind(this);
    }

    componentDidMount() {
        const routeId = this.props.match.params.id;
        if (routeId !== 'create') {
            this.setState({isCreating:false})
            this.loadRoute();
            this.loadFavorites();
            // this.setState({showNewPointButton:true});
        } else {
            this.setState({isLoading: false,showNewPointButton:false,isCreating:true})
        }
        this.getOperators();
    }

    loadRoute(){
        const routeId = this.props.match.params.id;
        this.getRoute(routeId);
    }
    async loadFavorites(){
        try{
            const favs = await API.routes.getFavorites();
            this.setState({
                favoritePOIS:favs.data.pois
            });
        }catch(ex){

        }

    }
    addToRoute(poi){
        this.setState({isEditingPoint:false,isFavoritesModalOpen:false}, () =>{
            this.setState({
                pointName: poi.name,
                pointInstructions: poi.instructions,
                pointAddress: poi.address,
                pointAddress2:'',
                pointZipCode: poi.zipCode,
                pointReference: poi.reference,
                pointBorough: poi.borough,
                pointLocality: poi.locality || '',
                pointIsEvidenceRequired: poi.isEvidenceRequired,
                pointMenuAddress:'',
                newPointLat: poi.latitude,
                newPointLon: poi.longitude,
                pointHour: (poi?.hour?.toString() || '').padStart(2,0),
                pointMinute: (poi?.minute?.toString() || '').padStart(2,0),
                pointIsFavorite: false,
                isNewPointVisible: true,
                newPointState:poi.state,
                routeAction:poi.action
            });
        })

    }
    getOperators() {
        API.users.getUsers('operator').then((res) => {
            this.setState({
                operators: res.data.users
            })
        }).catch((ex) => {

        })
    }
    goToMarker(marker){
        this.setState({
            viewport: {
                ...this.state.viewport,
                latitude: marker.latitude,
                longitude: marker.longitude,
                zoom: 13
            },
        })
    }
    showEvidences(poi){
        this.setState({showEvidences:true,shownPoi:poi});
    }
    getOrders(){
        API.orders.getOrders().then((res) =>{
            console.log('orders',res.data.orders);
            this.setState({
                orders:res?.data?.orders
            })
        }).catch((ex) =>{

        })
    }

    getRoute = (routeId) => {
        this.setState({isLoading: true})
        API.routes.getRoute(routeId).then((res) => {
            let route = {...res.data.route};
                route.logs = route.logs ? route.logs.reverse() : [];
            this.setState({route: route, isLoading: false}, () => {
                this.calcDistances();
            });
        }).catch((ex) => {
            this.setState({isLoading: false})
        })
    }

    onChange = (e) => {
        this.setState(prevState => {
            let route          = Object.assign({}, prevState.route);
            route[e.target.id] = e.target.value;
            return {route: route};
        });
    }

    onChangePoiInput = (e) => {
        this.setState({[e.target.id]:e.target.value});
    }

    onClickBtn    = () => {
        const {error, isValid} = this.validateRoute();
        if (!isValid) {
            return Swal.fire({
                icon: 'error',
                title: 'Error',
                html: error,
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            })
        }
        const regionId = this.props.match.params.id;
        if (regionId === 'create') {
            //create Route
            this.create();
        } else {
            //update Route
            this.update();
        }
    }

    validateRoute = () => {
        // check name
        let error     = '';
        if (!this.state.route.name.trim()) {
            error = 'El nombre no puede estar vacío';
            return {
                isValid: false,
                error: error
            }
        }
        return {
            isValid: true,
            error: null
        }
    }

    create        = () => {
        this.setState({isSaving:true});
        const txt = `¿Estás seguro de crear la ruta?`;
        Swal.fire({
            icon: 'warning',
            title: 'Crear ruta',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed) {
                API.routes.create(this.state.route).then((res) => {
                    window.location.replace(`/routes/${res.data.route._id}`);
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al crear la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                }).finally(() =>{
                    this.setState({isSaving:false});
                });

            }
        })
    }
    updateSilently = async () =>{
        const _route  = {...this.state.route};
        const pois  = _route.pois || [];
        _route.pois = pois;
        try{
            const res = await API.routes.update(_route._id, {route: _route});
            return Promise.resolve(res.data);
        }catch(ex){
            let errorMsg = 'Hubo un error al actualizar la ruta';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
                errorMsg = 'Servicio no disponible';
            }
            return Promise.reject(errorMsg);
        }
    }
    update = () => {
        this.setState({isSaving:true});
        const txt   = `¿Estás seguro de actualizar la ruta?`;
        const route = this.state.route;
        Swal.fire({
            icon: 'warning',
            title: 'Guardar ruta',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed) {
                const _route  = {...route};
                const pois  = _route.pois || [];
                _route.pois = pois;
                API.routes.update(route._id, {route: _route}).then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Ruta actualizada',
                        confirmButtonColor: '#4dda85',
                        heightAuto: false,
                        showCancelButton: true
                    });
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al actualizar la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                }).finally(() =>{
                    this.setState({isSaving:false});
                });
            }
        })

    }

    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto: false
        })
    }

    confirmDelete = () => {
        Swal.fire({
            icon: 'error',
            title: '¿Estás seguro de borrar la ruta?',
            html: 'Una vez borrada la región no se podrá recuperar',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
        }).then((res) => {
            if (res.isConfirmed) {
                const regionId = this.props.match.params.id;
                API.routes.delete(regionId).then((res) => {
                    this.props.history.push('/routes');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al borrar la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })
    }

    onDelete(value, index) {
        const route  = {...this.state.route};
        route.pois = route.pois.filter((o, i) => i !== index);
        this.setState({
            route: route
        }, () => {
            this.calcDistances();
        });

    }

    onClickMove(value, oldIndex, newIndex) {
        this.onSortEnd({oldIndex, newIndex});
    }

    onSortEnd({oldIndex, newIndex}) {
        const route  = {...this.state.route};
        route.pois = arrayMove(route.pois, oldIndex, newIndex);
        this.setState({
            route: route
        }, () => {
            this.calcDistances();
        })
    }

    generateGoogleMapsLink() {
        const route  = this.state.route;
        const pois = route.pois || [];
        if (pois.length > 1) {
            const origin      = pois[0];
            const destination = pois[pois.length - 1];
            let waypoints     = '';
            if (pois.length > 2) {
                waypoints = pois.slice(1, pois.length - 1).map((o) => {
                    return [o.latitude, o.longitude].join(',')
                }).join('|');

            }
            try {
                const link = `
            https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}
            &destination=${destination.latitude},${destination.longitude}&waypoints=${waypoints}`;
                return link;
            } catch (ex) {
                return '';
            }

        }

    }

    calcDistances() {
        const pois    = this.state.route?.pois || [];
        const distances = pois.map((poi, i) => {

            // add color
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            poi.color           = `#${randomColor}`;
            if (i < pois.length - 1) {
                const poi2 = pois[i + 1];
                const d         = getDistance(
                    {latitude: poi.latitude, longitude: poi.longitude},
                    {latitude: poi2.latitude, longitude: poi2.longitude},
                );
                return d / 1000;
            }
        });
        pois.map((poi, i) => {
            poi.distanceToNextPoint = distances[i];
        })
        this.setState({
            orders: pois,
            distances: distances
        })
    }

    setAddressState(value) {
        this.setState({newPointState:value});
    }
    validatePoiForm = () =>{
        if(this.state.pointName.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe el nombre del punto'
            }
        }
        if(this.state.pointInstructions.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe las instrucciones'
            }
        }
        if(!this.state.routeAction) {
            return {
                isValid: false,
                message: 'Selecciona una acción'
            }
        }
        if(this.state.pointAddress.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe la dirección'
            }
        }
        if(this.state.pointBorough.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe la delegación o colonia'
            }
        }
        if(this.state.pointLocality.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe la ciudad'
            }
        }
        if(this.state.pointZipCode.length !== 5) {
            return {
                isValid: false,
                message: 'El código postal debe de ser de 5 dígitos'
            }
        }
        if(this.state.pointReference.trim() === '') {
            return {
                isValid: false,
                message: 'Escribe una referencia'
            }
        }
        if(!this.state.newPointState) {
            return {
                isValid: false,
                message: 'Selecciona un estado'
            }
        }
        return {
            isValid:true,
            message:''
        }
    }
    async savePoi(){
        const validation = this.validatePoiForm();
        if(!validation.isValid){
            return this.fireSwalError(validation.message);
        }
        let _address = {
            name:this.state.pointName,
            address: this.state.pointAddress,
            borough: this.state.pointBorough,
            zipCode: this.state.pointZipCode,
            reference: this.state.pointReference,
            address2: this.state.pointAddress2,
            state: this.state.newPointState,
            locality: this.state.pointLocality,
            latitude: this.state.newPointLat,
            longitude: this.state.newPointLon,
            label:'poi',
            isEvidenceRequired:this.state.pointIsEvidenceRequired,
            hour: this.state.pointHour,
            minute: this.state.pointMinute,
            isFavorite: this.state.pointIsFavorite
        }
        let addressCreate;
        if(this.state.isEditingPoint){
            // Now add POI
            const poi = {
                ..._address,
                latitude:this.state.editingPOI?.latitude,
                longitude:this.state.editingPOI?.longitude,
                instructions:this.state.pointInstructions,
                action:this.state.routeAction
            }
            const routeId = this.props.match.params.id;

            API.routes.editPoi(routeId,this.state.editingPOI?._id,poi).then((res) =>{
                this.loadRoute();
                this.closePOIForm();
            }).catch((ex) =>{
                let errorMsg = 'Hubo un error al editar el punto';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {
                    errorMsg = 'Servicio no disponible';
                }
                return this.fireSwalError(errorMsg);
            });
        }else{
            this.setState({isSaving:true});
            try{
                addressCreate = await API.addresses.createPOI(_address);
            }catch(ex){
                let errorMsg = 'Hubo un error al crear el punto';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {
                    errorMsg = 'Servicio no disponible';
                }
                return this.fireSwalError(errorMsg);
            }finally {
                this.setState({isSaving:false});

            }
            // Now add POI
            const poi = {
                ..._address,
                latitude:addressCreate?.data?.address?.latitude,
                longitude:addressCreate?.data?.address?.longitude,
                instructions:this.state.pointInstructions,
                action:this.state.routeAction
            }
            const routeId = this.props.match.params.id;
            this.setState({isSaving:true});
            API.routes.addPoi(routeId,poi).then((res) =>{
                this.loadRoute();
                this.closePOIForm();
            }).catch((ex) =>{
            }).finally(() =>{
                this.setState({isSaving:false});
            });
        }



    }

    cleanAddPoiForm(){
        this.setState({
            pointName:'',
            pointAddress: '',
            pointBorough: '',
            pointZipCode: '',
            pointReference: '',
            newPointState: '',
            pointInstructions:'',
            routeAction:{},
            pointHour:"",
            pointMinute:""
        })
    }
    onMapClick(lat,lon){
        if(this.state.isCreating){
            return null;
        }
        if(this.state.showNewPointButton){
            this.setState({showNewPointButton:false,newPointLat:0,newPointLon:0});
        }else{
            this.setState({newPointLat:lat,newPointLon:lon,showNewPointButton:true});
            const mapWrapper = document.getElementById('map_wrapper');
            const mapWidth = mapWrapper.offsetWidth;
            const newPointUILeft = (mapWidth / 2) - 183;
            this.setState({
                newPointUILeft:  newPointUILeft
            })
            API.addresses.reverseGeocoding(lat,lon).then((res) =>{
                const features = res.data?.reverseGeocoding?.features || [];
                const address = features[0].place_name_es;
                const street = address.split(',')[0].trim();
                const zipCode  = features[1].text_es;
                let borough = features[2].text_es;
                const state = features[3].text_es;
                let locality = features[2].text_es;
                if(state === 'Ciudad de México'){
                    locality = 'Ciudad de México';
                }else{
                    borough = '';
                }
                console.log(street,zipCode,borough,state);
                this.setState({
                    pointAddress:street,
                    pointZipCode:zipCode,
                    pointBorough:borough,
                    newPointState:state,
                    pointLocality:locality
                })
                this.setState({
                    pointMenuAddress:address
                });
            }).catch((ex) =>{

            });
        }

    }
    onMarkerDrag(event,poi){
        if(this.state.viewport.zoom < 10){
            toast.info('Aumenta el zoom en el mapa mover el punto');
            return true;
        }
        if(poi?.order){
            toast.info('Los puntos con órdenes asignadas no pueden ser cambiados de ubicación');
            return true;
        }
        const route = {...this.state.route};
        const pois = [...route.pois];
        let p = pois.find((p) => p._id === poi._id);
        const prevLongitude = p.longitude;
        const prevLatitude = p.latitude;
        const newLongitude = event.lngLat[0];
        const newLatitude = event.lngLat[1];
        p.latitude = newLatitude;
        p.longitude = newLongitude;
        this.setState({route:route});
        toast.promise(this.updateSilently(), {
            loading: 'Guardando ruta...',
            success: (data) => {
                return ('Ruta guardada');
            },
            error: (ex) =>{
                p.latitude = prevLatitude;
                p.longitude = prevLongitude;
                this.setState({route:route});
                return (ex);
            },
            finally:() =>{
            }
        });

    }
    startEditing(poi){
        this.setState({isEditingPoint:true}, () =>{
            this.setState({
                pointName: poi.name,
                pointInstructions: poi.instructions,
                pointAddress: poi.address,
                pointAddress2:'',
                pointZipCode: poi.zipCode,
                pointReference: poi.reference,
                pointBorough: poi.borough,
                pointLocality: poi?.locality || '',
                pointIsEvidenceRequired: poi.isEvidenceRequired,
                pointMenuAddress:'',
                newPointLat: poi.latitude,
                newPointLon: poi.longitude,
                pointHour: (poi?.hour?.toString() || '').padStart(2,0),
                pointMinute: (poi?.minute?.toString() || '').padStart(2,0),
                pointIsFavorite: poi.isFavorite,
                isNewPointVisible: true,
                editingPOI:poi,
                newPointState:poi.state,
                routeAction:poi.action
            });
        })


    }
    renderFavoritesModal(){
        return(
            <Modal size="lg" centered
                   show={this.state.isFavoritesModalOpen}
                   onHide={() => {this.setState({isFavoritesModalOpen:false})}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Puntos Favoritos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {
                            this.state.favoritePOIS.map((poi) =>{
                                return(
                                    <ListGroupItem key={poi._id} className={'d-flex justify-content-between'}>
                                        {poi.address}
                                        <Button  size={'sm'} onClick={() => this.addToRoute(poi)} variant={'link'}>
                                            Agregar a la ruta
                                        </Button>
                                    </ListGroupItem>
                                )
                            })
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
            )

    }
    renderMap(showNewPointButton = true) {
        return (
            <div className={'w-100 position-relative d-flex'} id={'map_wrapper'}>
                {/*Route controls*/}
                {
                    this.state.showNewPointButton ?
                        <div className={"map-pois-controls"} style={{left: this.state.newPointUILeft,flex:0.7,textAlign:'center'}}>
                            {
                                ! this.state.pointMenuAddress ? <Spinner size={'sm'}></Spinner> :
                                    <>
                                        <div className={'mr-1'}>
                                            {this.state.pointMenuAddress}
                                        </div>
                                        <Button size={'sm'}
                                                style={{flex:1}}
                                                onClick={() => {
                                                    this.setState({isNewPointVisible: !this.state.isNewPointVisible})
                                                }}>
                                            Agregar punto
                                        </Button>
                                    </>
                            }


                        </div> : null
                }
                <ReactMapGL
                    {...this.state.viewport}
                    mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    onClick={(event) => {
                        this.onMapClick(event.lngLat[1],event.lngLat[0]);
                    }}
                    onViewportChange={(viewport) => {
                        this.setState({viewport: viewport})
                    }}
                >
                    {
                        this.state.route?.pois?.map((poi, index) => {
                            if (poi) {
                                return (
                                    <Marker longitude={poi?.longitude} latitude={poi?.latitude}
                                            key={poi?._id} draggable={true} captureClick={true}
                                            onDragEnd={(event) =>{
                                                this.onMarkerDrag(event,poi);
                                            }}
                                            onClick={(event) =>{
                                                // this.startEditing(poi);
                                            }}
                                    >
                                        {this.renderMarker(poi?.action,index + 1)}
                                        {/*<Badge bg={'primary'}>{index + 1}</Badge>*/}
                                    </Marker>
                                )
                            } else {
                                return null;
                            }

                        })
                    }
                    {
                        this.state.showNewPointButton && this.state.newPointLon && this.state.newPointLat ?
                            <Marker longitude={this.state.newPointLon} latitude={this.state.newPointLat}>
                                <Badge bg={'primary'}>
                                    <FontAwesomeIcon icon={'plus-circle'}/>
                                </Badge>
                                {/*<Badge bg={'primary'}>{index + 1}</Badge>*/}
                            </Marker> : null
                    }
                </ReactMapGL>
            </div>
        )
    }


    renderMarker(action, index){
        const foundAction = ROUTE_ACTIONS.find((a) => a.action === action);
        return(
            <Badge bg={foundAction?.variant} style={{cursor:'pointer'}}>
                {index} &nbsp;
                <FontAwesomeIcon icon={foundAction?.icon}/>
            </Badge>
        )
    }

    optimize() {
        // DUMMY WAY TO GET DISTANCES
        // DO NOT SCALE! YOU HEARD RIGHT!
        // DO NOT SCALE!
        // AGAIN: DO NOT SCALE!
        const orders = this.state.orders;
        orders.forEach((o, i) => {
            const shipping = o.shipping || {};
            orders.forEach((p) => {
                const shipping2 = p.shipping || {};
                const d         = getDistance(
                    {latitude: shipping.latitude, longitude: shipping.longitude},
                    {latitude: shipping2.latitude, longitude: shipping2.longitude},
                );
                // console.log(i,o._id,p._id,d);
            })


        })
        // _.sortBy(this.state.distances, function(num, index){
        //     console.log(num,index);
        // });
    }

    assignOperator(uid) {
        const routeId = this.props.match.params.id;
        API.routes.assignToOperator(routeId, uid).then(res => {
            this.getRoute(routeId);
        }).catch(ex => {
            let errorMsg = 'Hubo un error al asignar al operador';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        });
    }
    closePOIForm(){
        this.cleanAddPoiForm();
        this.setState({isNewPointVisible: false,showNewPointButton:false,isEditingPoint:false,editingPOI:{}});
    }
    render() {
        const route        = this.state.route;
        const routeId      = this.props.match.params.id;
        const operators    = this.state.operators || [];
        const assignedUserName = route.assignedUser ? `${route.assignedUser.name} ${route.assignedUser.last_name} (${route.assignedUser.email})` : "";
        const logs         = route.logs || [];

        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Toaster />
                {
                    this.state.showEvidences ?
                        <ViewEvidencesComponent
                            show={this.state.showEvidences}
                            poi={this.state.shownPoi}
                            onClose={() => this.setState({showEvidences:false})}
                        >
                        </ViewEvidencesComponent> : null
                }
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <h5 className={'text-capitalize mr-auto'}>{routeId === 'create' ? 'Crear ruta' : 'Ruta'}</h5>
                            <NPIf condition={routeId !== 'create'}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                        Configuración
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.confirmDelete()}>Borrar
                                            ruta</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </NPIf>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className='py-3' sm={12}>
                        <Form.Group as={Row} className="mt-4 mb-3" >
                            <Form.Label column sm={2}>Nombre</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" placeholder="Nombre" value={route.name} id={'name'}
                                              onChange={event => this.onChange(event)}/>
                            </Col>
                        </Form.Group>
                        {/*Operator Input*/}
                        <NPIf condition={routeId !== 'create'}>
                            <Form.Group as={Row} className="mt-4 mb-3">
                                <Form.Label column sm={2}>Operador</Form.Label>
                                <Col sm={10}>
                                    <InputGroup>
                                        <Form.Control type={"text"}
                                                      disabled={true}
                                                      value={assignedUserName} />


                                        <DropdownButton as={InputGroup} size={'sm'}
                                                        variant="outline-primary"
                                                        className={'text-uppercase'}
                                                        title={'Asignar operador'} align={'end'}>
                                            {
                                                operators.map(operator => {
                                                    return (
                                                        <Dropdown.Item key={operator.uid}
                                                                       className={'text-capitalize'}
                                                                       onClick={() => this.assignOperator(operator.uid)}>
                                                            {operator.name} {operator.last_name}
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                        <NPIf condition={!assignedUserName}>
                                            <Form.Control.Feedback className={"d-block"} type={"invalid"} >
                                                El operador no ha sido asignado
                                            </Form.Control.Feedback>
                                        </NPIf>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </NPIf>
                    </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col sm={12} className={'d-flex justify-content-end'}>*/}
                {/*        <Button className={'ml-1'} onClick={() => this.optimize('short-distances')}>Optimizar ruta</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className={'mb-5'}>
                    <Col sm={12} md={12} lg={6} >
                        <div className={'d-flex justify-content-end mb-1'}>
                            <Button size={'sm'} onClick={() => {
                                this.setState({isFavoritesModalOpen:true})
                                this.loadFavorites();
                            }} disabled={this.state.isCreating}>
                                <FontAwesomeIcon icon={"star"}></FontAwesomeIcon>
                            </Button>
                        </div>

                        {this.renderFavoritesModal()}
                        {this.renderMap(this.state.showNewPointButton)}
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                        <SortableComponent
                            items={this.state.route.pois}
                            onSortEnd={this.onSortEnd}
                            onClickMove={this.onClickMove}
                            distances={this.state.distances}
                            onDelete={this.onDelete}
                            onHover={(marker) => this.goToMarker(marker)}
                            onViewEvidences={(poi) => this.showEvidences(poi)}
                            onEdit={(poi) => this.startEditing(poi)}
                        >
                        </SortableComponent>
                        <br/>
                        <b>
                            Distancia total (lineal): {
                            parseFloat(this.state.distances.filter((d) => d).reduce((a, b) => a + b, 0)).toFixed(2)
                        } Km.
                        </b>
                        <NPIf condition={this.props.match.params.id !== 'create'}>
                            <Row>
                                <b>
                                    Estado de la ruta {this.renderStatus()}
                                </b>
                            </Row>
                        </NPIf>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-end'}>
                        <Button variant="link"
                                href={this.generateGoogleMapsLink()} target={'blank'}>Ver ruta en Google Maps</Button>

                        <Button className={'ml-1'} onClick={() => this.onClickBtn()}>Guardar</Button>
                    </Col>
                </Row>
                <Row className={'mt-4 mb-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Historial de la ruta</Card.Title>
                                {
                                    logs.map((log) => {
                                        return (
                                            <Row className={'mb-4'}>
                                                <Col sm={2} style={{display: 'flex', alignItems: 'center'}}>
                                                    <b>{dayjs(log.created_at).format('DD MMM HH:mm, YYYY')}</b>
                                                </Col>
                                                <Col sm={8} style={{display: 'flex', alignItems: 'center'}}>
                                                    <span style={{fontSize: 25, marginRight: 10}}>
                                                        {
                                                            log.logType === 'viewed' ? '👀' : ''
                                                        }
                                                    </span>
                                                    {log.log}
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/*Modal Start*/}
                <Modal size="lg" centered
                       show={this.state.isNewPointVisible}
                       onHide={() => {this.closePOIForm()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.isEditingPoint ? 'Editar punto' : 'Agregar punto'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Nombre del punto</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"
                                              placeholder="Nombre del punto"
                                              value={this.state.pointName}
                                              id={'pointName'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Instrucciones</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" as="textarea"
                                              placeholder="Instrucciones"
                                              value={this.state.pointInstructions}
                                              id={'pointInstructions'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Acción</Form.Label>
                            <Col sm={8} className={'m-t-5'}>
                                <div>
                                    {
                                        ROUTE_ACTIONS.map((action) => {
                                            return (
                                                <Button size={'sm'}
                                                        className={'m-r-5'}
                                                        disabled={this.state.editingPOI?.order}
                                                        variant={ this.state.routeAction === action?.action ? action.variant : `outline-${action.variant}`}
                                                        onClick={() => this.setState({'routeAction':action.action})}>
                                                    {action.name}&nbsp;
                                                    <FontAwesomeIcon icon={action.icon}/>
                                                </Button>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Horario</Form.Label>
                            <Col sm={3} md={3} lg={2}>
                                <Form.Select  id="pointHour"
                                              value={this.state.pointHour}
                                              placeholder="00"
                                              onChange={(e) => this.setState({pointHour:e.target.value})}>
                                    {
                                        HOURS.map((hour, index) => {
                                            return (
                                                <option value={hour} key={index}>{hour}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                            <span className={"w-auto ps-0 pe-0 align-self-center"}>:</span>
                            <Col sm={3} md={3} lg={2}>
                                <Form.Select  id="pointMinute"
                                              value={this.state.pointMinute}
                                              placeholder="00"
                                              onChange={(e) => this.setState({pointMinute:e.target.value})}>
                                    {
                                        MINUTES.map((hour, index) => {
                                            return (
                                                <option value={hour} key={index}>{hour}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Form.Label column sm={4}></Form.Label>
                            <Col sm={8}>
                                <Form.Check
                                    type={"checkbox"}
                                    label={`Requiere subir evidencia`}
                                    id={`pointIsEvidenceRequired`}
                                    disabled={this.state.editingPOI?.order}
                                    value={this.state.pointIsEvidenceRequired}
                                    onChange={event => {
                                        this.setState({pointIsEvidenceRequired: event.target.checked})
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        <hr/>

                        <Form.Group as={Row} className="mt-4 mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Calle y número</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"
                                              placeholder="Calle y numero"
                                              disabled={this.state.editingPOI?.order}
                                              value={this.state.pointAddress}
                                              id={'pointAddress'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Delegación o colonia</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"
                                              placeholder="Delegación o colonia"
                                              value={this.state.pointBorough}
                                              disabled={this.state.editingPOI?.order}
                                              id={'pointBorough'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Ciudad</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"
                                              placeholder="Ciudad"
                                              value={this.state.pointLocality}
                                              id={'pointLocality'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Código postal</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"
                                              placeholder="Código postal"
                                              value={this.state.pointZipCode}
                                              disabled={this.state.editingPOI?.order}
                                              id={'pointZipCode'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Estado</Form.Label>
                            <Col sm={8} className={'m-t-5'}>
                                <Form.Select  id="state"
                                              value={this.state.newPointState}
                                              disabled={this.state.editingPOI?.order}
                                              placeholder="Selecciona un estado."
                                              onChange={(e) => this.setAddressState(e.target.value)}>
                                    <option value="">Selecciona un estado</option>
                                    {
                                        addressStates.map((state, index) => {
                                            return (
                                                <option value={state} key={index}>{state}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className={'m-t-5'}>Referencia</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" as="textarea"
                                              placeholder="Referencia"
                                              value={this.state.pointReference}
                                              disabled={this.state.editingPOI?.order}
                                              id={'pointReference'}
                                              onChange={event => this.onChangePoiInput(event)}/>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-0">
                            <Form.Label column sm={4}></Form.Label>
                            <Col sm={8}>
                                <Form.Label style={{cursor:"pointer"}}
                                            onClick={() => {
                                                if(!this.state.editingPOI?.order){
                                                    this.setState({pointIsFavorite: !this.state.pointIsFavorite});
                                                }
                                            }}>
                                    <FontAwesomeIcon icon={[this.state.pointIsFavorite ? "fa" :"far", "star"]}
                                                     color={this.state.pointIsFavorite ? "#ffc409" : "black"} />
                                    &nbsp; Marcar como favorito
                                </Form.Label>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="medium" size={'sm'}
                                onClick={() => { this.cleanAddPoiForm();this.setState({isNewPointVisible: false,showNewPointButton:false})}}>
                            Cerrar
                        </Button>
                        <Button size={'sm'} onClick={() => this.savePoi()} disabled={this.state.isSaving}>
                            {
                                this.state.isSaving ? <Spinner size={'sm'}></Spinner> : 'Guardar'
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/*Modal End*/}
            </Container>
        )
    }

    renderStatus() {
        const route = this.state.route;
        if (route.status === 'open') {
            return <Badge bg={'primary'}>Abierta</Badge>
        } else if (route.status === 'on_route') {
            return <Badge bg={'warning'}>En ruta</Badge>
        } else if (route.status === 'finished') {
            return <Badge bg={'success'}>Terminada</Badge>
        } else {
            return <Badge>{route.status}</Badge>
        }
    }
}

export default withRouter(Route);
