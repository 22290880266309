/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {withRouter} from "react-router";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalHeader from "react-bootstrap/ModalHeader";

//components
import ImageSelector from "../ui/ImageSelector";

class ViewEvidencesComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            poi: {
                evidences: [],
            },
            rowIndex: -1,
            selectedImage: '',
        }

        this.loadOrder                      = this.loadOrder.bind(this);
    }
    componentDidMount() {
        // this.loadOrder();
        const evidences = this.props.poi.evidences || [];
        if(evidences.length > 0){
            this.setState({selectedImage:evidences[0].imageUrl});
        }
    }
    loadOrder(){
        // const orderId = this.props.match.params.id;
        // NbioApi.orders.getOrder(orderId).then((res) => {
        //     const selectedImage = res.data.order.tickets.length > 0 ? res.data.order.tickets[0].imageUrl : '';
        //     const hasDiscount = res.data.order.items.some((i) => !!i.discount);
        //     this.setState({
        //         order: res.data.order,
        //         selectedImage: selectedImage,
        //         hasDiscount: hasDiscount
        //     });
        // })
    }

    render(){
        const evidences = this.props.poi.evidences || [];
        return(
            <Modal show={this.props.show} fullscreen={true}>
                <ModalHeader closeButton onHide={() => this.props.onClose()}>
                    <h5>Evidencias</h5>
                </ModalHeader>
                <ModalBody>
                    <Container fluid className={'h-100 d-flex flex-column'}>
                        <Row className={'flex-grow-1 mt-2'}>
                            <Col sm={12} md={12} >
                                {
                                    evidences.length === 0 ?
                                        'Aún no hay evidencias':
                                        <ImageSelector images={evidences}
                                                       selectedImage={this.state.selectedImage}
                                                       onImageSelected={(img) => this.setState({selectedImage:img})}>
                                        </ImageSelector>

                                }

                            </Col>
                        </Row>

                    </Container>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>

        )
    }
}

export default withRouter(ViewEvidencesComponent);
